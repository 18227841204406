$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    // create instance of the plugin, but hold on till needed
    var acc = $('.accHandler').accordion({onhold: true, openFirst: true});
    $win = $(window);

    // start the accordion when window is smaller than 902px
    $win.resize(function() {
        if (breakpoint['medium']) acc.destroy();
        else acc.init();
    });
  
    // trigger resize to check if accordion is needed
    $win.trigger('resize');

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * REPLACE a[href^="tel"] BY SPAN ELEMENT IF NOT MOBILE DEVICE
     */
    if(!('ontouchstart' in window || navigator.maxTouchPoints)) {
        phonelink();
    }

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'iframe',
        maxWidth: $(window).width() * 0.65,
        maxHeight: $(window).width() * 0.65 * 0.5625,
        iframe: {
            width: $(window).width() * 0.65,
            height: $(window).width() * 0.65 * 0.5625,
        }
    });


    /**
     * detect external links and add target=_blank and then add rel="noopener"
     */
    $('a:external').attr('target', '_blank');
    $('a[target="_blank"]:not(.phone)').each(function() {
        var str = 'noopener';
        var attr = (this.getAttribute('rel') === null ? str : this.getAttribute('rel').replace(str, '') + ' ' + str);
        this.setAttribute('rel', attr);
    });

    $('.scroll-down').click(function(){
        event.preventDefault();
        $('html,body').animate({
            scrollTop:$('#firstrow').offset().top - $('.page-navi').height()
        }, 700);
    });
    if (breakpoint['medium']) {
        // navi scroll setup
        var $navC = $(".container-fixed");

        // navi on scroll function
        function navScrollHandler() {
            // if we scrolled more than one pixel
            if ($win.scrollTop() > 1) {
                $navC.addClass("is_reduced");
            }
            // we did not scroll more than one pixel
            else {
                $navC.removeClass("is_reduced");
            }
        }

        // use navi on scroll function on first load
        navScrollHandler();
        // use navi on scroll function on scroll (non-throttled)
        $win.on('scroll', navScrollHandler);
    }
});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/